import BaseService from "src/packages/BaseService";

export default class LevelService extends BaseService {
	static get className() {
		return "level";
	}


	static async zip(levelId) {
		let response = await LevelService.getService().get("level/zip/" + levelId);
		return response.data;
	}
};
