class RowMixin {
  constructor (types, modelName) {
    return {
      data: () => {
        return {};
      },
      watch: {
        form: {}
      },
      methods: {
        onRemove () {
          this.$store.dispatch(types.REMOVE, this[modelName]);
        },
        onEdit () {
          console.log("RowMixin onEdit ------");
          this.$store.commit(types.GET, this[modelName]);
          this.$emit("edit");
        }
      },
      mounted () {
        this.$children.forEach((ele) => {
          if (ele._isVue) {
            if (ele.$options.name === "remove-btn") {
              ele.$on("remove", this.onRemove);
            } else if (ele.$options.name === "edit-btn") {
              ele.$on("click", this.onEdit);
            }
          }
        });
      },
      destroyed () {
        this.$children.forEach((ele) => {
          if (ele._isVue) {
            if (ele.$options.name === "remove-btn") {
              ele.$off("remove", this.onRemove);
            } else if (ele.$options.name === "edit-btn") {
              ele.$off("click", this.onEdit);
            }
          }
        });
      }
    };
  }
}

export default RowMixin;
