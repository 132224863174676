<template>
	<v-data-table
		:headers="headers"
		:items="users"
		:loading="loading"
		class="elevation-1"
    :search="seek"
	>
    <template v-slot:top>
      <v-text-field
          v-model="seek"
          label="חיפוש חופשי"
          class="mx-4"
      ></v-text-field>
    </template>
		<v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
<!--		<user-row slot="items" slot-scope="props" @edit="onEdit" :user="props.item"/>-->
    <template #item.actions="{item}">
      <edit-btn @click="$emit('edit',item)"/>
      <v-btn icon @click="createToken(item)">
        <v-icon>mdi-key-plus</v-icon>
      </v-btn>

        <v-btn small color="primary" @click="loginAs(item)">כנס כמשתמש</v-btn>


    </template>
	</v-data-table>
</template>

<script>
import UserRow from "src/view/users/UserRow";
import {types} from "src/store/modules/User.module";
import UserService from "src/services/UserService";
import FilterAble from "src/mixins/FilterAble";
import AuthService from "src/packages/AuthService";

export default {
		name: "users-table",
		props: [],
		components: {
			UserRow
		},
		mixins: [new FilterAble(types)],
		data () {
			return {
        seek:"",
				loading: false,
				headers: [
					{ text: "שם", value: "name" },
					{ text: "last_name", value: "last_name" },
					{ text: "דואר אלקטרוני", value: "email" },
          {text: "פרוייקט",value:"project.name"},
          {text: "actions",value:"actions"}
				],
				search: null,
				showDialog: false
			};
		},
		computed: {
			users () {
				return this.$store.state.UserModule.users;
			},
		},
		methods: {
      async loginAs(user) {
        this.loading = true;
        await AuthService.loginAs(user.id);
        window.location.replace("/");
      },
			onEdit (User) {
				this.$emit("edit", User);
			},
      createToken(user){
        swal.fire({
          title: 'שם המפתח',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off',
            requierd:'true'
          },
          showCancelButton: true,
          confirmButtonText: 'Create Key',
          showLoaderOnConfirm: true,
           preConfirm: async (name) => {
             try {
               return await UserService.createToken({
                 user_id: user.id,
                 token_name:name
               });
             } catch (error) {
               swal.showValidationMessage(
                   `Something get wrong`
               )
             }
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.value) {
            swal.fire({
              text: result.value.token,
              title: 'יש לשמור על הטוקן, אי אפשר להציגו מחדש!'
            })
          }
        })
      }
		}
	};
</script>
