<!--suppress ALL -->
<!--suppress UnreachableCodeJS -->
<template>

	<form @submit.prevent="onSubmit">

		<smart-input :error="inputErrors.name" label='name' v-model="form.name" required></smart-input>
		<smart-input :error="inputErrors.prefix" :disabled="!isNew" label='prefix' v-model="form.prefix" required></smart-input>
		<smart-input :error="inputErrors.domain" label='domain' v-model="form.domain"></smart-input>
		<smart-input :error="inputErrors.schema" label='schema' v-model="form.schema" textarea></smart-input>
		<smart-input :error="inputErrors.cloudinary_secret" label='cloudinary_secret' v-model="form.cloudinary_secret"></smart-input>
		<smart-input :error="inputErrors.cloudinary_key" label='cloudinary_key' v-model="form.cloudinary_key"></smart-input>
		<div class="form-group">
			<v-btn type="submit" color="success">שמירה</v-btn>
		</div>
	</form>
</template>


<script>
	import ProjectModule from "src/store/modules/Project.module";
	import FormView from "src/mixins/FormView";

	export default {
		props: [],
		name: "project-form",
		components: {},
		data() {
			return {
				form: {
					name: null,
				},
			};
		},
		mixins: [new FormView(ProjectModule,"project")],
		computed: {
			isNew(){
				return !this.project.id;
			}
		}
	};
</script>

<style>

</style>
