<template>

	<page>
		<title-view>ניהול קהילות
			<v-btn slot="actions" btn color="primary" @click="onEdit">הוספת קהילה</v-btn>
			<v-btn slot="actions" btn color="warning" class="mr-3" @click="uploadExcel">העלאת אקסל קהילות</v-btn>
		</title-view>
		<row>
			<c-col>
				<organizations-table :key="tableKey" @edit="onEdit"/>
			</c-col>
		</row>
		<popup-dialog v-if="openModal" v-model="openModal">
			<span slot="header">יצירת/עריכת קהילה</span>
			<organization-form @close="openModal=false" @save="openModal=false"/>
		</popup-dialog>

		<popup-dialog v-if="excelModal" v-model="excelModal">
			<span slot="header">העלאת אקסל</span>
			<v-alert color="warning">יש לבקש אקסל מותאם מcloseapp!</v-alert>
			<form @submit.prevent="submitExcel">
				<v-file-input
					label="File input"
					outlined
					dense
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					name="import"></v-file-input>
				<v-btn :loading="uploadingExcel" color="success" type="submit">העלאה</v-btn>
			</form>
		</popup-dialog>

	</page>


</template>

<script>
	import OrganizationForm from "src/view/organizations/OrganizationForm";
	import OrganizationsTable from "src/view/organizations/OrganizationsTable";
	import OrganizationModule from "src/store/modules/Organization.module";
  import MyAxios from "src/packages/MyAxios";
  import swal from "sweetalert2";

	export default {
		name: "organizations-view",
		components: {OrganizationsTable, OrganizationForm},
		data() {
			return {
				openModal: false,
				excelModal: false,
        uploadingExcel:false,
        tableKey:1
      };
		},
		methods: {
			onEdit(organization) {
				if (!organization.id) {
					this.$store.commit(OrganizationModule.types.CREATE, organization);
				} else
					this.$store.commit(OrganizationModule.types.GET, organization);
				this.openModal = true;
			},
			uploadExcel() {
				this.excelModal = true;
			},
			async submitExcel(e) {
        const data = new FormData(e.target);
        try {
          this.uploadingExcel=true;
          await MyAxios.post("/import/organization", data);
          this.excelModal = false;
          this.tableKey = this.tableKey+1;
          swal.fire("העלאת הקבוצות הצליחה", "","success");
        } catch (e) {
          swal.fire("קרתה תקלה","אנא פנה לתמיכה", "error");
        }
        this.uploadingExcel=false;
      }
		}

	};
</script>

<style scoped>

</style>
