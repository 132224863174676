class SelectMixin {
  constructor (module, modelsName) {
    return {
      data: () => {
        return {
          // model: null,
        };
      },
      props: {
        value: {},
        taggable: { type: Boolean, default: false },
        name: { type: String },
        label: { default: "label" },
        filters: Object,
        lazy: {type: Boolean, default: false},
      },
      computed: {
        [modelsName] () {
          return module.state[modelsName];
        },
        options () {
          return module.state[modelsName];
        },
        model: {
          get () {
            if (!isNaN(this.value)) {
              return this.options.find((model) => {
                return model.id === this.value;
              });
            } else if (typeof (this.value) === "object") {
              if (this.value[this.label]) {
                return this.value;
              }
            } else {
              return {
                [this.label]: this.value
              };
            }
          },
          set (value) {
            this.$emit("input", value && value.id ? value.id : value);
          }
        }
      },
      watch: {
        value () {
          // this.setModel();
          // this.model = this.value;
          if (!this.model && !isNaN(this.value)) {
            this.$store.dispatch(module.types.FETCH, { id: this.value });
          }
        },
        filters(to, from) {
          if (JSON.stringify(to) !== JSON.stringify(from)) {
            this.getData();
          }
        }
      },
      methods: {
        onSearch (value) {
          this.$store.dispatch(module.types.FETCH, { [this.label]: value });
        },
        onInput () {

        },
        setModel () {

        },
        getData() {
          this.$store.dispatch(module.types.FETCH, this.filters).then(this.setModel);

        }
      },
      created () {
        console.log(this.filters);
        if (!this.lazy)
          this.getData();
      },
      destroyed () {

      }
    };
  }
}

export default SelectMixin;
