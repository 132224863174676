import BaseService from "./BaseService";

export default class AuthService extends BaseService {
  static get className () {
    return "auth";
  }

  static login (params) {
    return BaseService.getService().post("login", params).then(
      response => {
        return response.data;
      }
    );
  }

  static loginAs(id) {
    return BaseService.getService().get(`login/user/${id}`).then(
        response => {
          return response.data;
        }
    );
  }

  static getAuth () {
    return BaseService.getService().get("auth").then(
      response => {
        return response.data;
      }
    );
  }

  static getPin () {
    return BaseService.getService().get("pin").then(
      response => {
        return response.data;
      }
    );
  }

  static logout () {
    return BaseService.getService().post("logout").then(
      response => {
        return response.data;
      }
    );
  }

  static checkMail (email) {
    return BaseService.getService().get("users/exist", { params: { email } }).then(
      response => {
        return response.data;
      }
    );
  }
};
