<template>
	<v-btn fab small dark color="indigo"
		class="mr-2"
		@click="onClick"
	>
		<v-icon dark>
			edit
		</v-icon>
	</v-btn>
</template>

<script>
	export default {
		name: "edit-btn",
		props: ["data"],
		components: {},
		data() {
			return {};
		},
		computed: {},
		methods: {
			onClick() {
				this.$emit("click", this.data);
			}
		},
		watch: {},
		created() {
		},
		mounted() {
		},
		updated() {
		},
		destroyed() {
		}
	};
</script>

<style scoped>

</style>
