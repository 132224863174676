const files = require.context(".", false, /\.js$/);
const config = {};

files.keys().forEach(fileName => {
  if (fileName === "./index.js")
    return;
  const moduleName = fileName.replace(/(\.\/|\.js)/g, "");
  config[moduleName] = files(fileName).default;
});
export default config;
