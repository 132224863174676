<template>
	<v-autocomplete
		:label="theLabel"
		:required="required"
		:rules="rules"
		v-bind="$attrs"

		v-model="model"
		:items="items"
		:prepend-icon="icon"
		@keyup.native="keyup"
		:item-text="item_text"
		:item-value="item_value"
	>
	</v-autocomplete>
	<!--<v-textarea v-else-->
	<!--v-model="model"-->
	<!--:label="theLabel"-->
	<!--:required="required"-->
	<!--:rules="rules"-->
	<!--v-bind="$attrs">-->
	<!--</v-textarea>-->
</template>

<script>
	export default {
		name: "select-object-autocomplete",
		props: {
			keyup: { type: Function },
			item_text: { type: String },
			item_value: { type: String },
			icon: { type: String },
			items: { type: Array },
			label: { type: String },
			value: {},
			name: { type: String },
			required: { type: Boolean },
			textarea: {
				type: Boolean, default: false
			}
		},
		components: {},
		data () {
			return {};
		},
		computed: {
			model: {
				get () {
					return this.value;
				},
				set (val) {
					this.$emit("input", val);
				}
			},
			theLabel () {
				return this.required ? this.label + "*" : this.label;
			},
			rules () {
				let rules = [];
				if (this.required) {
					rules.push(v => !!v || `${this.theLabel} הוא שדה חובה`);
				}
				return rules;
			}
		}

	};
</script>

<style scoped>

</style>
