const FilterAble = function (types) {
  return {
    props:{params:{type:Object,default:()=>{return {}}}},
    data: function () {
      return {
        filters: {
          page: 1
        },
        settings: {},
        loading: false
      };
    },
    methods: {
      changePage (page) {
        this.filters.page = page;
        this.updateQuery();
        this.getData();
      },
      order ({ orderBy, reverse }) {
        this.filters.orderBy = orderBy;
        this.filters.reverse = reverse;
        this.filters.page = 1;
        this.updateQuery();
        this.getData();
      },
      filter ({ filterName, filterValue }) {
        this.filters[filterName] = filterValue;
        this.filters.page = 1;
        this.updateQuery();
        this.getData();
      },
      async getData (silent = false) {
        if (!silent) {
          this.updateQuery();
        }
        this.loading = true;
        let response;

          response = await this.getDataFromServer();

        this.loading = false;
        return response;
      },
      getDataFromServer () {
        return this.$store.dispatch(types.FETCH, Object.assign({},this.filters,this.params)).then((response) => {
          if (!Array.isArray(response)) {
            this.settings = response;
          }
          return response;
        });
      },
      updateQuery () {
        this.$router.replace({
          query: Object.assign({}, this.filters)
        });
      }
    },
    created: function () {
      this.filters = Object.assign(this.filters, this.$route.query);
      this.getData(true);
    }
  };
};
export default FilterAble;
