import Vue from "vue";
import PageView from "./PageView";
import Row from "./Row";
import cCol from "./cCol";
import TitleView from "./TitleView";
import BlockLine from "./BlockLine";
import SmartInput from "./SmartInput";
import SelectObjectAutocomplete from "./SelectObjectAutocomplete";
import MessageBox from "./MessageBox";
import PopupDialog from "./PopupDialog";
import EditBtn from "src/scaffoldings/EditBtn";
import RemoveBtn from "src/scaffoldings/RemoveBtn";
import ColsRow from "src/scaffoldings/ColsRow";

export default {
  PageView,
  Row,
  BlockLine,
  TitleView,
  cCol,
  SmartInput,
  MessageBox
};

Vue.component("page", PageView);
Vue.component("row", Row);
Vue.component("cols-row", ColsRow);
Vue.component("title-view", TitleView);
Vue.component("block-line", BlockLine);
Vue.component("smart-input", SmartInput);
Vue.component("select-object-autocomplete", SelectObjectAutocomplete);
Vue.component("c-col", cCol);
Vue.component("message-box", MessageBox);
Vue.component("popup-dialog", PopupDialog);
Vue.component("edit-btn", EditBtn);
Vue.component("remove-btn", RemoveBtn);
