export default {
    default: "admin",

    routes: {
        admin: {
            defaultPage: "organizations-page",
            routes: [
                {title: "קהילות", name: "organizations-page", icon: "fas fa-users"},
                {title: "פרוייקטים", name: "projects-page", icon: "fas fa-users"},
                {title: "זכיינים", name: "users-page", icon: "fas fa-users"}

            ]
        },
        franchiser: {
            defaultPage: "organizations-page",
            routes: [
                {title: "קהילות", name: "organizations-page", icon: "fas fa-users"},
            ]
        },
        manager: {
            defaultPage: "groups-page",
            routes: [
                {title: "שחקנים", name: "groups-page", icon: "fas fa-users"}
            ]
        }
    }
};
