/**
 * Created by elad on 22 מרץ 2017.
 */
import * as axios from "axios";

let $router = null;
let $baseUrl = "api";
export const setRouter = (router) => {
  $router = router;
};
export const setBaseUrl = (path) => {
  $baseUrl = path;
};
const myAxios = axios.create({
  baseURL: "api/"
});
myAxios.interceptors.request.use(function (request) {
  request.baseURL = "/" + $baseUrl + "/";
  return request;
});
myAxios.interceptors.response.use(function (response) {
  // Do something with response data
  // return null;
  return response;
}, function (error) {
  window.ee = error;
  // Do something with response error
  const response = error.response;
  if (response && response.status == 401) {
    // TODO go to login page
    if ($router) {
      $router.replace({ name: "login-page" });
    }
  } else {
    // TODO ALERT ERROR;
  }
  console.log(error);
  return Promise.reject(error);
});

export default myAxios;
