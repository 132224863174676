<template>
	<v-text-field :is="tagName"
		v-model="model"
		:label="label"
		:required="required"
		:counter="counter"
		ref="input"
		v-bind="$attrs"
		v-validate="ruleString"
		:error-messages="errorCollection"
		:data-vv-name="label"
		:name="name"
		auto-grow
		data="error"
		rows="2"

	>
		<template v-slot:append>
			<v-icon x-small v-if="required" color="red lighten-2">mdi-asterisk</v-icon>
		</template>
	</v-text-field>
</template>

<script>
	export default {
		$_veeValidate: {
			validator: "new",
			name() {
				return this.label;
			},
			value() {
				return this.$refs.input.value;
			}
		},
		name: "smart-input",
		props: {
			label: {type: String},
			value: {},
			name: {type: String},
			rules: {type: String},
			required: {type: Boolean},
			textarea: {
				type: Boolean, default: false
			},
			error: String
		},
		methods: {
			getAttrsRule(attrs) {
				let validAttrs = ["minlength", "maxlength", "min", "max"];
				const dic = {"minlength": "min", "maxlength": "max"};
				let rules = [this.rules];
				Object.keys(attrs).forEach((rule) => {
					if (validAttrs.indexOf(rule) > -1) {
						let key = dic[rule] || rule;
						rules.push(`${key}:${attrs[rule]}`);
					}
				});
				return rules.join("|");

			}
		},
		computed: {
			errorCollection() {
				let errors = this.errors.collect(this.label);
				if (this.error) {
					errors.push(this.error);
				}
				return errors;
			},
			type() {
				return this.$attrs.type || "text";
			},
			counter() {
				return (this.$attrs.maxlength) ? +this.$attrs.maxlength : null;
			},
			model: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit("input", val);
				}
			},
			theLabel() {
				return (this.required ? "* " : "") + this.label;
			},
			ruleString() {
				let rules = [];
				if (this.required) {
					rules.push("required");
				}
				if (this.type === "email") {
					rules.push("email");
				}
				let attrs = this.getAttrsRule(this.$attrs);
				if (attrs) {
					rules.push(attrs);
				}
				return rules.join("|");
			},
			tagName() {
				if (this.type === "checkbox") {
					return "v-checkbox";
				} else if (this.type === "radio") {
					return "v-radio";
				}
				return this.textarea ? "v-textarea" : "v-text-field";
			}

		}

	};
</script>

<style scoped>

</style>
