var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.levels,"single-expand":_vm.singleExpand,"loading":_vm.loading,"item-key":"level","expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.gLength",fn:function(ref){
var item = ref.item;
return [_vm._v("\n\t\t"+_vm._s(Object.keys(item.groups).length)+"\n\t")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('level-table',{attrs:{"groups":item.groups}})],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.downloadProduct(item)}}},[_vm._v("הורד תוצרי שלב")]),(_vm.getZipLinks(item).downloadImageLink)?_c('v-btn',{on:{"click":function($event){_vm.download(_vm.getZipLinks(item).downloadImageLink)}}},[_vm._v("\n\t\t\tהורד תמונות\n\t\t")]):_vm._e(),(_vm.getZipLinks(item).downloadVideoLink)?_c('v-btn',{on:{"click":function($event){_vm.download(_vm.getZipLinks(item).downloadVideoLink)}}},[_vm._v("\n\t\t\tהורד וידאו\n\t\t")]):_vm._e()]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }