import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

const Notification = {};
let service = function (message, setting = {}) {
  let defaultSetting = {
    title: "הודעת מערכת",
    color: "",
    message: message,
    rtl: true,
    position: "topCenter"
  };
  iziToast.show(Object.assign(defaultSetting, setting));
};
let success = function (message = "הפעולה הצליחה", setting = {}) {
  let defaultSetting = {
    title: "הודעת מערכת",
    message: message,
    rtl: true,
    position: "topCenter"
  };
  iziToast.success(Object.assign(defaultSetting, setting));
};
let error = function (message = "שגיאה, אנא בדוק את כל השדות", setting = {}) {
  let defaultSetting = {
    title: "הודעת מערכת",
    message: message,
    rtl: true,
    timeout: false,
    position: "topCenter"

  };
  iziToast.error(Object.assign(defaultSetting, setting));
};
Notification.install = function (Vue, options) {
  Vue.prototype.$notfication = {
    alert (err) {
      alert(err);
    },
    warning (title = "האם אתה בטוח?", text = "", opri = {}) {
      let def = {
        title: title,
        text: text,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33"
      };
      return swal.fire(Object.assign(def, options));
    },
    service,
    success,
    error,
    clean(){
      iziToast.destroy();
    }
  };
};
export default Notification;
