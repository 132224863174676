<!--suppress ALL -->
<!--suppress UnreachableCodeJS -->
<template>

	<form @submit.prevent="onSubmit">

		<smart-input :error="inputErrors.name" label='name' v-model="form.name"></smart-input>
		<smart-input :error="inputErrors.last_name" label='last_name' v-model="form.last_name"></smart-input>
		<smart-input :error="inputErrors.email" type="email" label='email' v-model="form.email"></smart-input>
    <projects-select v-model="form.project_id"/>
		<smart-input :error="inputErrors.password" type="password" label='password' v-model="form.password"></smart-input>
		<div class="form-group">
			<v-btn type="submit" color="success">שמירה</v-btn>
		</div>
	</form>
</template>

<script>
	import UserModule from "src/store/modules/User.module";
	import FormView from "src/mixins/FormView";
  import ProjectsSelect from "../projects/ProjectsSelect";

	export default {
		props: [],
		name: "user-form",
		components: {ProjectsSelect},
		data () {
			return {
				form: {
					name: null
				}
			};
		},
		mixins: [new FormView(UserModule, "user")],
		computed: {
			isNew () {
				consol.log(this.user);
				return !!this.user.id;
			}
		}
	};
</script>

<style>

</style>
