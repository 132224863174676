/**
 * Created by elad on 20 נובמבר 2016.
 */
import UserService from "src/packages/AuthService";

export const types = {
  LOGIN: "auth/login",
  GET: "auth/get",
  PASSWORD: "auth/password",
  LOGOUT: "auth/logout",
  SAVE: "auth/save",
  STORE: "auth/store",
  UPDATE: "auth/update"

};
export default {

  state: {
    user: {}

  },
  getters: {
    isConnected(state) {
      return !!state.user.id;
    },
    isManager(state) {
      return +state.user.role === 1;
    },
    project(state) {
      return state.user.project || {};
    }
  },
  mutations: {
    [types.LOGIN] (state, userLogin) {
      state.user = userLogin;
    },
    [types.LOGOUT] (state) {
      state.user = {};
    }

  },

  actions: {
    [types.LOGIN] (context, form) {
      return UserService.login(form).then((response) => {
        context.commit(types.LOGIN, response);
        return response;
      });
    },
    [types.GET] (context) {
      return UserService.getAuth().then((response) => {
        context.commit(types.LOGIN, response);
        return response.data;
      });
    },
    [types.SAVE] (context, user) {
      if (user.id) {
        return context.dispatch(types.UPDATE, user);
      } else {
        return context.dispatch(types.STORE, user);
      }
    },
    [types.STORE] (context, data) {
      return UserService.create(data).then((response) => {
        context.commit(types.LOGIN, response);
        return response;
      });
    },
    [types.UPDATE] (context, data) {
      return UserService.update(data.id, data).then((response) => {
        context.commit(types.LOGIN, response);
        return response;
      });
    },
    [types.PASSWORD] (state, form) {
      return UserService.sendPassword(form).then((response) => {
        return response.data;
      });
    },
    [types.LOGOUT] (context) {
      return UserService.logout()
        .then(
          response => {
            if (response.success) {
              context.commit(types.LOGOUT);
            }
            return response;
          }
        );
    }

  },
  types: types
};
