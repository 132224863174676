import BaseMiddleware from "src/middleware/BaseMiddleware";
import { types as authType } from "src/store/modules/Auth.module";
import store from "src/store";

class GuestMiddleware extends BaseMiddleware {
  static goToInstitutes (next) {
    return next({
      name: "institute-view"
    });
  }

  async handle (from, to, next, args) {
    if (store.getters.isConnected) {
      return GuestMiddleware.goToInstitutes(next);
    } else {
      try {
        let response = await store.dispatch(authType.GET);
        if (store.getters.isConnected) {
          return GuestMiddleware.goToInstitutes(next);
        } else {
          return next();
        }
      } catch (e) {
        return next();
      }
    }
  }
}

export const rules = {};

export default GuestMiddleware;
