class LaravelErrors {
  static getFirstErrorOnly (laravelErrors) {
    let errors = {};
    Object.keys(laravelErrors).forEach((key) => {
      errors[key] = laravelErrors[key][0];
    });
    return errors;
  }
}

export default LaravelErrors;
