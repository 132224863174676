import axios from "./MyAxios";

/**
 * Created by elad on 20 נובמבר 2016.
 */

export default class BaseService {

  /**
   *
   * @returns {AxiosPromise}
   */
  static getService() {
    return axios;
  }

  static create(data) {
    return axios.post(this.className, data).then(
        (response) => {
          return response.data;
        }
    );
  }

  static update (id, data) {
    data._method = "put";
    return axios.post(this.className + "/" + id, data).then(
      (response) => {
        return response.data;
      }
    );
  }

  static fetch (params = {}) {
    return axios.get(this.className, { params }).then(
      (response) => {
        return response.data;
      }
    );
  }

  static get (id, params = {}) {
    return axios.get(this.className + "/" + id, { params }).then(
      response => {
        if (response.data) {
          return response.data;
        } else {
          throw new Error(response.data);
        }
      }
    );
  }

  static remove (id, dataUrl = {}) {
    dataUrl._method = "delete";
    return axios.post(this.className + "/" + id, dataUrl).then(
      response => {
        if (response.data) {
          return response.data;
        } else {
          throw new Error(response.data);
        }
      }
    );
  }

  static save (data) {
    if (data.id) {
      return this.update(data.id, data);
    } else {
      return this.create(data);
    }
  }

  static get className () {
    return null;
  }

  static removeFromArray (arr, obj) {
    let clone = arr.slice(0);
    let index = clone.indexOf(obj);
    if (index > -1) {
      clone.splice(index, 1);
    }
    return clone;
  }

  static removeFromArrayById (arr, id) {
    return arr.filter((o, index, object) => {
      return o.id != id;
    });
  }

  static UpdateArray (arr, obj) {
    let index = arr.indexOf(obj);
    if (index > -1) {
      arr[index] = obj;
      return true;
    }
    return false;
  }

  static UpdateArrayById (models, obj) {
    let clone = models.slice(0);
    let model = clone.find((model) => {
      return model.id === obj.id;
    });
    if (model) {
      Object.assign(model, obj);
    }
    return clone;
  }
};
