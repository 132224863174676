<template>
	<v-dialog persistent transition="dialog-top-transition"  v-bind="$attrs" v-model="openModal" max-width="600px">
		<v-card>
			<v-toolbar dark color="primary">
				<v-btn icon dark @click="openModal = false">
					<v-icon>close</v-icon>
				</v-btn>
				<v-toolbar-title>
					<slot name="header"></slot>
				</v-toolbar-title>
				<v-spacer></v-spacer>

			</v-toolbar>
			<v-card-title primary-title>

			</v-card-title>
			<v-card-text>
				<slot></slot>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<slot name="action"></slot>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		props: ["value"],
		components: {},
		data() {
			return {};
		},
		computed: {
			openModal: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit("input", val);
				}
			}
		}
	};
</script>

<style scoped>

</style>
