import BaseService from "src/packages/BaseService";
import GroupsFirestore from "dev/firebase/GroupsFirestore";
import store  from "dev/store";
export default class GroupService extends BaseService {
	static get className() {
		return "group";
	}


	static fetch(params = {}) {
		let user = store.state.AuthModule.user;
		return (new GroupsFirestore(user.project.prefix,params.code || user.organization.code)).getGroups();
	}


};
