<!--suppress UnnecessaryLabelJS -->
<template>
	<v-data-table
		:headers="headers"
		:items="levels"
		:single-expand="singleExpand"
		:loading="loading"
		item-key="level"
		class="elevation-1"
		:expanded.sync="expanded"
		show-expand
	>
		<v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
		<template #item.gLength="{item}">
			{{Object.keys(item.groups).length}}
		</template>
		<template v-slot:expanded-item="{ headers, item }">
			<td :colspan="headers.length">
				<level-table :groups="item.groups"></level-table>
			</td>
		</template>
		<template #item.action="{item}">
			<v-btn @click="downloadProduct(item)">הורד תוצרי שלב</v-btn>
			<v-btn v-if="getZipLinks(item).downloadImageLink" @click="download(getZipLinks(item).downloadImageLink)">
				הורד תמונות
			</v-btn>
			<v-btn v-if="getZipLinks(item).downloadVideoLink" @click="download(getZipLinks(item).downloadVideoLink)">
				הורד וידאו
			</v-btn>
		</template>
	</v-data-table>
</template>

<script>
	import {types} from "src/store/modules/Level.module";
	import FilterAble from "src/mixins/FilterAble";
	import LevelTable from "dev/view/levels/LevelTable";
	import LevelService from "dev/services/LevelService";

	export default {
		name: "levels-table",
		props: [],
		mixins: [new FilterAble(types)],
		components: {LevelTable},
		data() {
			return {
				singleExpand: true,
				loading: false,
				expanded: [],
				headers: [
					{text: "שם השלב", value: "level"},
					{text: "מספר שחקנים", value: "gLength"},
					{text: "action", value: "action"},
				],
				search: null,
				zips: {},
				showDialog: false
			};
		},
		computed: {
			levels() {
				return this.$store.state.LevelModule.levels;
			}
		},
		methods: {
			getZipLinks(item) {
				return this.zips[item.level] || {};
			},
			download(link) {
				window.open(link, "_blank");
			},
			async downloadProduct(item) {
				this.loading = true;
				try {
					let response = await LevelService.zip(item.level);
					this.zips[item.level] = {};

					this.zips[item.level].downloadImageLink = response.image.secure_url;
					this.zips[item.level].downloadVideoLink = response.video.secure_url;
					//window.open(response.secure_url, "_blank");
				} finally {
					this.loading = false;
				}
			}
		}

	};
</script>

<style>

</style>
