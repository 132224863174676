<script>
	export default {
		name: "row",
		props: ["col"],
		render (createElement) {
			return createElement("v-row", {
				attrs: Object.assign({ row: true }, this.$attrs)
			}, [this.$slots.default]);
		},
		components: {},
		data () {
			return {};
		},
		computed: {},

		methods: {},
		watch: {},
		created () {
			window.ww = this;
		},
		mounted () {
		},
		updated () {
		},
		destroyed () {
		}
	};
</script>

<style scoped>

</style>
