<template>
	<v-btn fab small color="white" @click="onClick">
		<v-icon color="#BDBDBD" dark>delete</v-icon>
	</v-btn>
</template>

<script>
	export default {
		name: "remove-btn",
		props: {
			data: {required: false, default: null},
			options: {
				type: Object, default: () => {
					return {};
				}
			}
		},
		components: {},
		data() {
			return {};
		},
		computed: {},
		methods: {
			onClick() {
				this.$emit("click", this.data);
				this.$notfication.warning(undefined, undefined, this.options).then((event) => {
					if (event.value) {
						this.$emit("remove", event.value);
					}
				});
			}
		}
	};
</script>

<style scoped>
	button {
		/*float: left;*/
	}

	.red-color {
		color: #cccccc !important;
	}
</style>
