<!--suppress ALL -->

<template>
	<tr>
		<td class="text-xs-center">
			{{user.name }}
		</td>
		<td class="text-xs-center">
			{{user.last_name }}
		</td>
		<td class="text-xs-center">
			{{user.email }}
		</td>
		<td>
			<edit-btn @click="onEdit"/>
			<remove-btn @remove="doRemove"/>
		</td>

	</tr>
</template>

<script>

	import { types } from "src/store/modules/User.module";
	import RemoveBtn from "src/scaffoldings/RemoveBtn";
	import EditBtn from "src/scaffoldings/EditBtn";
	import RowMixin from "src/mixins/RowMixin";

	export default {
		name: "user-row",
		props: {
			user: { required: true }
		},
		components: { EditBtn, RemoveBtn },
		mixins: [new RowMixin(types, "user")],
		data () {
			return {};
		},
		computed: {},
		methods: {
			doRemove () {
				this.$store.dispatch(types.REMOVE, this.user);
			},
			onEdit () {
				this.$emit("edit", this.user);
			}
		}
	};
</script>

<style>

</style>
