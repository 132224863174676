<template>

	<page>
		<title-view>מסך משתמשים
			<v-btn slot="actions" text @click="onEdit">הוספת משתמש</v-btn>
		</title-view>
		<row>
			<c-col>
				<users-table :params="{role:0}" @edit="onEdit"/>
			</c-col>
		</row>
		<popup-dialog v-model="openModal">
			<span slot="header">יצירת/עריכת User</span>
			<user-form @save="openModal=false"/>
		</popup-dialog>

	</page>

</template>

<script>
	import UserForm from "src/view/users/UserForm";
	import UsersTable from "src/view/users/UsersTable";
	import UserModule from "src/store/modules/User.module";

	export default {
		name: "users-view",
		components: { UsersTable, UserForm },
		data () {
			return {
				openModal: false
			};
		},
		methods: {
			onEdit (user) {
        if (user.id) {
          this.$store.dispatch(UserModule.types.GET, user.id);
        }else{
          this.$store.commit(UserModule.types.CREATE, user);

        }
				this.openModal = true;
			}
		}

	};
</script>

<style scoped>

</style>
