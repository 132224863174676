import Pipeline from "../helpers/Pipeline";

const isCallback = (foo) => {
  return foo && typeof foo === "function";
};

class RouterMiddleware {
  constructor (middleware) {
    this.middleware = middleware;
  }

  check (to, from, next) {
    this.runMiddleware(to, from, next);
  }

  _getMiddleware (to) {
    const metaArray = Object.keys(this.middleware);
    let middleware = [];
    metaArray.forEach((pipe) => {
      if (to.matched.some(m => m.meta[pipe])) {
        middleware.push(this.middleware[pipe]);
      }
    });
    return middleware;
  }

  async runMiddleware (to, from, next) {
    let middleware = this._getMiddleware(to);
    if (!middleware.length) return next();
    const pipeline = new Pipeline(middleware);
    pipeline.setBreak((input) => {
      return !isCallback(input);
    });
    pipeline.inject([to, from]);
    let response = await pipeline.process(this.generateNextFun());
    if (isCallback(response)) {
      return next();
    }
    return next(response);
  }

  generateNextFun () {
    return (obj) => {
      if (obj) {
        return obj;
      } else {
        return this.generateNextFun();
      }
    };
  }
}

export default RouterMiddleware;
