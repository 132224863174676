import BaseService from "src/packages/BaseService";

export default class UserLessonService extends BaseService {
  static get className () {
    return "user";
  }

  static async createToken(payload){
    let response = await BaseService.getService().post("/tokens/create",payload);
    return response.data;
  }
};
