import Vue from "vue";
import Vuex from "vuex";
import AuthModule from "./modules/Auth.module";
import UserModule from "./modules/User.module";
import ConfigModule from "./modules/Config.module";
import OrganizationModule from "./modules/Organization.module";
import GroupModule from "./modules/Group.module";
import LevelModule from "./modules/Level.module";
import ProjectModule from "./modules/Project.module";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    loading: false
  },
  modules: {
    AuthModule,
    UserModule,
    ConfigModule, GroupModule,
    OrganizationModule,
    ProjectModule,
    LevelModule
  }
});
export default store;

export const config = function (key, value) {
  store.commit(ConfigModule.types.SET, { key, value });
};
window.cc = config;
