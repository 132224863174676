<!--suppress ALL -->

<template>
	<tr>
		<td class="text-xs-center">
			{{project.id }}
		</td><td class="text-xs-center">
			{{project.name }}
		</td>
		<td class="text-xs-center">
			{{project.prefix }}
		</td>
		<td class="text-xs-center">
			{{project.domain }}
		</td>
		<td class="text-xs-center">
			{{project.schema }}
		</td>
		<td class="text-xs-center">
			{{project.cloudinary_secret }}
		</td>
		<td class="text-xs-center">
			{{project.cloudinary_key }}
		</td>
		<td>
			<edit-btn @click="onEdit"/>
			<remove-btn/>
		</td>

	</tr>
</template>

<script>

	import {types} from "src/store/modules/Project.module";
	import RemoveBtn from "src/scaffoldings/RemoveBtn";
	import EditBtn from "src/scaffoldings/EditBtn";
	import RowMixin from "src/mixins/RowMixin";

	export default {
		name: "project-row",
		props: {
			project:{required:true}
		},
		components: {EditBtn, RemoveBtn},
		mixins: [new RowMixin(types, "project")],
		data() {
			return {};
		},
		computed: {},
		methods: {
			doRemove() {
				this.$store.dispatch(types.REMOVE, this.project).then(() => {
				});
			},
			onEdit() {
				this.$emit("edit", this.project);
			}
		}
	};
</script>

<style>


</style>
