export default {
    roles: [
        {id: 2, text: "מנהל על", rule: "admin"},
        {id: 0, text: "זכיין", rule: "franchiser"},
        {id: 1, text: "רכז", rule: "manager"},
    ],
    rules: {
        admin: {route: "admin", baseUrl: "api/admin"},
        franchiser: {route: "franchiser", baseUrl: "api/admin"},
        manager: {route: "manager"},
    }
};
