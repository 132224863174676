var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"single-expand":_vm.singleExpand,"loading":_vm.loading,"item-key":"id","expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.complete",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.complete),callback:function ($$v) {_vm.$set(item, "complete", $$v)},expression:"item.complete"}})]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.url)?_c('a',{attrs:{"href":_vm.imageTiny500(item.url),"target":"_blank"}},[(_vm.isUriImage(item.url))?_c('v-img',{attrs:{"src":_vm.imageTiny(item.url)}}):_c('span',[_vm._v("לחץ לצפייה")])],1):_c('span',[_vm._v("ללא תוצרים")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('pre',{staticStyle:{"direction":"ltr"}},[_vm._v(_vm._s(item))])])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }