var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.organizations,"loading":_vm.loading,"search":_vm.seek},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"חיפוש חופשי"},model:{value:(_vm.seek),callback:function ($$v) {_vm.seek=$$v},expression:"seek"}})]},proxy:true},{key:"item.insertAs",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.loginAs(item.user)}}},[_vm._v("כנס כמשתמש")]):_vm._e()]}},{key:"item.user.email",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('a',{attrs:{"href":("mailto:" + (item.user.email))}},[_vm._v(_vm._s(item.user.email))]):_vm._e()]}},{key:"item.link_continue",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.link_continue,"target":"_blank"}},[_vm._v("קישור המשך")])]}},{key:"item.link_slide",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://yahad.clap.co.il/assets/2/atsmaut/v7/?theKey=" + (item.link_slide)),"target":"_blank"}},[_vm._v("קישור מצגת")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('edit-btn',{on:{"click":function($event){return _vm.editItem(item)}}}),_c('v-btn',{attrs:{"href":("/api/admin/organizations/json/" + (item.id)),"target":"_blank"}},[_vm._v("JSON\n        "),_c('v-icon',[_vm._v("\n          download\n        ")])],1),_c('v-btn',{attrs:{"small":"","dark":"","color":"primary","fab":"","to":{name:'group-id-page',params:{org_id:item.id}}}},[_c('v-icon',[_vm._v("fas fa-users")])],1)]}},{key:"item.is_open",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"label":item.is_open ? 'המשחק פתוח' : 'המשחק סגור'},on:{"change":function($event){return _vm.onChange(item)}},model:{value:(item.is_open),callback:function ($$v) {_vm.$set(item, "is_open", $$v)},expression:"item.is_open"}})]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }