<template>
	<v-app id="inspire">

		<v-main>
			<v-container fluid fill-height>
				<v-layout justify-center align-start>
					<v-flex>
						<router-view></router-view>
					</v-flex>
				</v-layout>
			</v-container>
		</v-main>
		<v-footer
			color="indigo"
			app
		>
			<span class="white--text">Closeapp &copy; 2019</span>
		</v-footer>
	</v-app>
</template>

<script>
	export default {
		name: "app"
	};
</script>
