import store from "dev/store";
import {Config} from "dev/store/modules/Config.module";

export const types = {
	ADMIN: 0,
	MANAGER: 2,
};

function getRoles() {
	return store.getters.rolesConfig.roles;
}

function findTheRole(roleId) {
	return getRoles().find(role => +role.id === roleId);
}

function getTheRule(roleId) {
	let role = findTheRole(roleId);
	return store.getters.rolesConfig.rules[role.rule];
}


function updateConfig(user) {
	let role = getTheRule(user.role);
	if (role.route) {
		Config("routes.default", role.route);
		Config("menu.default", role.route);
	}
	if (role.baseUrl) {
		Config("request.baseUrl", role.baseUrl);
	}
}

store.watch((state) => state.AuthModule.user, (user) => {
	updateConfig(user);
});

export default {
	get roles() {
		return getRoles();
	},
	toString(id) {
		return findTheRole(id).text;
	},
	getRule(ruleText) {
		return this.getRuleById(store.state.AuthModule.user, ruleText);
	},
	getRuleById(role, ruleText) {
		let rule = getTheRule(role);
		return ruleText ? rule[ruleText] : rule;
	},
	getRoleById: findTheRole,


};
