import RouterMiddleware from "src/middleware/RouterMiddleware";
import AuthMiddleware from "src/middleware/AuthMiddleware";
import AlertMiddleware from "src/middleware/AlertMiddleware";
import GuestMiddleware from "src/middleware/GuestMiddleware";

export default new RouterMiddleware({
  "RequireAuth": AuthMiddleware,
  "auth": AuthMiddleware,
  "guest": GuestMiddleware,
  "alert": AlertMiddleware
});
