<template>
	<row>
		<div class="col-xs-12">
			<slot></slot>
		</div>
	</row>
</template>

<script>
	export default {
		name: "block-line",
		props: [],
		components: {},
		data () {
			return {};
		},
		computed: {},
		methods: {},
		watch: {},
		created () {
		},
		mounted () {
		},
		updated () {
		},
		destroyed () {
		}
	};
</script>

<style scoped>

</style>
