<template>
	<v-toolbar class="mb-4">
		<v-toolbar-title>
			<slot></slot>
		</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-toolbar-items class="hidden-sm-and-down">
			<slot name="actions"></slot>
		</v-toolbar-items>
	</v-toolbar>
</template>

<script>
	export default {
		name: "title-view",
		props: [],
		components: {},
		data () {
			return {};
		},
		computed: {},
		methods: {},
		watch: {},
		created () {
		},
		mounted () {
		},
		updated () {
		},
		destroyed () {
		}
	};
</script>

<style scoped>

</style>
