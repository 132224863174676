import BaseMiddleware from "src/middleware/BaseMiddleware";
import { types as authType } from "src/store/modules/Auth.module";
import store from "src/store";

class AuthMiddleware extends BaseMiddleware {
  static goToLogin (next) {
    return next({
      name: "login-page"
    });
  }

  async handle (next, to, from) {
    if (!store.getters.isConnected) {
      try {
        await store.dispatch(authType.GET);
        if (!store.getters.isConnected) {
          return AuthMiddleware.goToLogin(next);
        } else {
          return next();
        }
      } catch (e) {
        return AuthMiddleware.goToLogin(next);
      }
    } else {
      return next();
    }
  }
}

export const rules = {};
AuthMiddleware.rules = {
  CLIENT: "client",
  ADMIN: "admin"
};

export default AuthMiddleware;
