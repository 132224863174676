import BaseService from "src/packages/BaseService";

function createTypes (name) {
  return {
    SAVE: name + "/save",
    GET: name + "/get",
    FETCH: name + "/fetch",
    REMOVE: name + "/remove",
    STORE: name + "/store",
    UPDATE: name + "/update",
    COUNT: name + "/count",
    CREATE: name + "/create"
  };
}

function createState ({ name, plural }) {
  let obj = {};
  obj[name] = {};
  obj[plural] = [];
  return obj;
}

function createMutation (name, plural, types, params) {
  return {
    [types.FETCH] (state, objects) {
      state[plural] = objects;
    },
    [types.STORE] (state, obj) {
      state[plural].unshift(obj);
    },
    [types.UPDATE] (state, obj) {
      state[plural] = BaseService.UpdateArrayById(state[plural], obj);
      const model = state[name];
      if (+model.id === +obj.id) {
        state[name] = obj;
      }
    },
    [types.REMOVE] (state, obj) {
      state[plural] = BaseService.removeFromArray(state[plural], obj);
    },
    [types.GET] (state, obj) {
      state[name] = obj;
    },
    [types.CREATE] (state, obj) {
      state[name] = Object.assign({}, params, obj);
    }
  };
}

function createActions (name, types, service) {
  return {
    [types.FETCH] (context, filters) {
      return service.fetch(filters).then((response) => {
        if (response.data) context.commit(types.FETCH, response.data);
        else context.commit(types.FETCH, response);
        return response;
      });
    },
    [types.GET] (context, id) {
      // console.log()
      return service.get(id).then((response) => {
        context.commit(types.GET, response);
        return response;
      });
    },
    [types.SAVE] (context, model) {
      if (model.id) {
        return context.dispatch(types.UPDATE, model);
      } else {
        return context.dispatch(types.STORE, model);
      }
    },
    [types.REMOVE] (context, model) {
      return service.remove(model.id).then((response) => {
        context.commit(types.REMOVE, model);
        return response;
      });
    },
    [types.STORE] (context, model) {
      return service.create(model).then((response) => {
        context.commit(types.STORE, response);
        return response;
      });
    },
    [types.UPDATE] (context, model) {
      return service.update(model.id, model).then((response) => {
        context.commit(types.UPDATE, response);
        return response;
      });
    }
  };
}

function createNameObj (name) {
  if (typeof name === "string") {
    name = { name, plural: name + "s" };
  }
  if (!name.plural) {
    name.plural = name + "s";
  }
  return name;
}

const creator = (name, service, extendModule = {}, types, params = {}) => {
  let baseModule = Object.assign({}, extendModule);
  const nameObj = createNameObj(name);
  baseModule.types = Object.assign({}, createTypes(nameObj.name), extendModule.types || {});
  for (let name in baseModule.types) {
    types[name] = baseModule.types[name];
  }
  baseModule.state = Object.assign({}, createState(nameObj), extendModule.state || {});
  baseModule.mutations = Object.assign({}, createMutation(nameObj.name, nameObj.plural, baseModule.types, params), extendModule.mutations || {});
  baseModule.actions = Object.assign({}, createActions(nameObj.name, baseModule.types, service), extendModule.actions || {});
  return baseModule;
};

export default creator;
