/**
 * Created by elad on 15 נובמבר 2016.
 */
import VueRouter from "vue-router";
import middleware from "src/middleware";
import LoginView from "src/view/auth/LoginView";
import Dashboard from "./Dashboard";
import OrganizationsView from "./view/organizations/OrganizationsView";
import store from "dev/store";
import GroupsView from "dev/view/groups/GroupsView";
import ProjectsView from "./view/projects/ProjectsView";
import UsersView from "./view/users/UsersView";

const routes = [
    {path: "/", redirect: "dashboard", name: "home-page"},
    {path: "/login", component: LoginView, name: "login-page"},
    {
        path: "/dashboard",
        component: Dashboard,
        meta: {"RequireAuth": true},
        children: [
            {
                path: "/", redirect: () => {
                    let config = store.state.ConfigModule;
                    return {name: config.menu.routes[config.menu.default].defaultPage};
                },
            },
            {path: "/organizations", component: OrganizationsView, name: "organizations-page"},
            {path: "/groups", component: GroupsView, name: "groups-page"},
            {path: "/groups/:org_id", component: GroupsView, name: "group-id-page"},
            {path: "/projects", component: ProjectsView, name: "projects-page"},
            {path: "/franchiser", component: UsersView, name: "users-page"},

        ]
    }
];

const router = new VueRouter({
    history: false,
    mode: "history",
    routes
});

router.beforeEach((to, from, next) => {
    middleware.check(to, from, next);
});

export default router;
