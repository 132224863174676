import Connection from "dev/firebase/Connection";

export default class GameFirestore {

    /**
     *
     * @param project
     * @param code
     */
    constructor(project, code) {
        this.project = project;
        this.code = code;
    }

    /**
     *
     * @returns {*}
     */
    getProjectDoc() {
        const db = Connection.db;
        return db.collection("projects").doc(this.project).collection("games").doc("c" + this.code);
    }

    getUserLevelData(levelName) {
        return this.getProjectDoc()
            .collection("levels")
            .doc(levelName)
            .collection("groups")
            .doc(this.groupId).get().then((doc) => {
                if (doc) {
                    return doc.data();
                }
                return null;
            });
    }

    getGroups(groupIds) {
        if (!groupIds) {
            return this.getProjectDoc()
                .collection("groups").get().then((snapshot) => {
                    return snapshot.docs.map(doc => Object.assign({id: doc.id}, doc.data()));
                });
        }
        return this.getProjectDoc()
            .collection("groups").get().then((result) => {
                return result.docs.filter((doc) => groupIds.indexOf(doc.id) > -1);
            });
    }


    getLevel(levelName) {
        return this.getProjectDoc()
            .collection("levels")
            .doc(levelName)
            .collection("groups")
            .get().then((doc) => {
                if (doc) {
                    let groupIds = doc.docs.map(doc => doc.id);
                    return this.getGroups(groupIds).then((groupsQuery) => {
                        let groups = new Map();
                        groupsQuery.forEach((doc) => {
                            groups.set(doc.id, doc.data());
                        });
                        return doc.docs.map(doc => {
                            return Object.assign({}, groups.get(doc.id), {data: doc.data()});
                        });
                    });
                }
                return null;
            });
    }


}
