<!--suppress ALL -->
<template>
	<v-select :items="options" v-model="model" label="בחר" item-text="name" item-value="id"></v-select>
</template>

<script>
	import ProjectModule from "src/store/modules/Project.module";
	import SelectMixin from "src/mixins/SelectMixin";

	export default {
		name: "projects-select",
		props: {value: {default: null}},
		components: {},
		mixins: [new SelectMixin(ProjectModule, "projects")],
	};
</script>
