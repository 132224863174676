import BaseService from "src/packages/BaseService";

export default class OrganizationService extends BaseService {
	static get className() {
		return "organization";
	}

	static clearData(organizationId) {
		return OrganizationService.getService().delete("clear/organization/" + organizationId);
	}

	 static async openClose(organizationId,params){
		const res = await OrganizationService.getService().post("organization/open/" + organizationId,params);
		return res.data;
	}
};
