import LevelService from "src/services/LevelService";
import ModuleCreator from "src/store/ModuleCreator";

export const types = {};
export const params = {
	name:"",
};

/**
@namespace
@property  {object} module,
@property  {object} module.state,
@property  {object} modules.level,
@property  {array}  modules.levels
**/

const levelStore = {
	state: {},
	mutations: {},
	getters: {},
	actions: {},
	types: types
};
export default ModuleCreator("level", LevelService, levelStore, types ,params);
