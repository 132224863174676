import firebase from "firebase/app";
// Required for side-effects
import "firebase/firestore";


/**
 *  import * as firebase from "firebase-admin";
 * */


class Connection {

    get config() {
        return {
            apiKey: "AIzaSyBkWyZ80UDXe4CC3IllC8qW791kbim7ILQ",
            authDomain: "atzmaut-closeapp.firebaseapp.com",
            databaseURL: "https://atzmaut-closeapp.firebaseio.com",
            projectId: "atzmaut-closeapp",
            storageBucket: "atzmaut-closeapp.appspot.com",
            messagingSenderId: "488375805777",
            appId: "1:488375805777:web:9d4ed641ca2bf43dbd1570",
            measurementId: "G-WXXBN44Y5P"
        };
    }

    /**
     *
     * @returns firebase-admin
     */
    get firebase() {
        return firebase;
    }

    /** Initialize Firebase**/
    init() {
        firebase.initializeApp(this.config);
        //  firebase.analytics();
    }

    get db() {
        const db = firebase.firestore();
        /*if (location.hostname === "localhost") {
            db.settings({
                host: "localhost:8080",
                ssl: false
            });
        }*/
        return db;
    }

}

export default new Connection();


