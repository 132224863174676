<!--suppress UnnecessaryLabelJS -->
<template>
	<v-card flat>
		<v-expansion-panels v-model="panel">
			<v-expansion-panel>
				<v-expansion-panel-header>
					חיפוש מתקדם
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-container>
						<v-row>
							<v-col cols="12" lg="3" v-for="f in filterFields" :key="f.value">
								<smart-input :error="error[f.value]" :name="f.value" :label="f.text" :type="f.type || 'text'" v-model="searchFields[f.value]"/>
							</v-col>
						</v-row>
					</v-container>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-container>
			<v-data-table
				:headers="headers"
				:items="filterGroups"
				:search="seek"
				:single-expand="singleExpand"
				:loading="loading"
				class="elevation-1"
				item-key="id"
				:expanded.sync="expanded"
				show-expand
			>
				<template v-slot:top>
          <v-container>
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                    v-model="seek"
                    label="חיפוש חופשי"
                    class="mx-4"
                ></v-text-field>
              </v-col>
              <v-col class="text-left">
                <download-excel class="v-btn v-btn--contained theme--light v-size--default primary" name="players.xls"
                                :export-fields="jsonFields" :data="filterGroups">
                  ייצוא לאקסל
                </download-excel>
              </v-col>
            </v-row>
          </v-container>
        </template>
				<v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
				<template #item.complete="{item}">
					<v-simple-checkbox v-model="item.complete" disabled></v-simple-checkbox>
				</template>
        <template v-slot:expanded-item="{ headers, item }">
					<td :colspan="headers.length">
						<pre style="direction: ltr;text-align: left">{{ item }}</pre>
					</td>
				</template>
			</v-data-table>
		</v-container>
	</v-card>
</template>

<script>
import {types} from "src/store/modules/Group.module";
import FilterAble from "src/mixins/FilterAble";

export default {
  name: "groups-table",
  props: [],
  components: {},
  mixins: [new FilterAble(types)],
  data() {
    return {
      panel: null,
      seek: "",
      singleExpand: true,
      loading: false,
				expanded: [],
				search: null,
				showDialog: false,
				filters: {
					page: 1,
					naa: 22
				},
				error: {},
				searchFields: {}
			};
		},
		computed: {
      jsonFields() {
        return this.headers.reduce((previousValue, {value, text}) => {
          previousValue[text] = value;
          return previousValue;
        }, {})
      },
      filterFields() {
        let base = [
          {text: "שחקן", value: "name"},
          {text: "תאריך", type: "date", value: "last_login"},
        ];
        let additional = this.groupFields;
        return [...base, ...additional];
      },
      groupFields() {
        return this.organization.fields ? Object.keys(this.organization.fields).map((key) => {
					return {
						text: this.organization.fields[key],
						value: key
					};
				}) : [];
			},
			headers() {
				let base = [
					{text: "שחקן", value: "name"},
					{text: "תאריך", value: "last_login"},
				];
				let additional = this.groupFields;
				return [...base, ...additional];

			},
      user() {
        return this.$store.state.AuthModule.user;
      },
			organization() {
				return this.$store.state.OrganizationModule.organization.id ?
            this.$store.state.OrganizationModule.organization :
            this.user.organization;
			},
			groups() {
				return this.$store.state.GroupModule.groups;
			},
			filterGroups() {
				return this.groups.filter((group) => {
					let show = true;
					this.filterFields.forEach(({value,type}) => {
						let filter = this.searchFields[value];
						if (filter) {
              if (!group[value]){
                show = false
              }
              else if (type === "date"){
                if (!moment(group[value]).isSame(moment(filter),"day")){
                  show = false;
                }
              } else if (!group[value].includes(filter)) {
                show = false;
              }
            }
          });
          return show;
        }).map((group) => {

          return Object.assign({}, group, {last_login: this.getDateFormat(group.last_login)})
        });
			}
		},
		methods: {
			getDateFormat(date){
        if (date) {
          return moment(date).format("DD-MM-YYYY HH:mm");
        }
        return "";
			}
		}
	};
</script>

<style>

</style>
