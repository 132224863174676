<template>
	<v-col v-bind="$attrs">
		<slot></slot>
	</v-col>
</template>
<script>
	export default {
		name: "c-col",

		components: {},
		data () {
			return {};
		},
		computed: {},

		methods: {},
		watch: {},
		created () {
		},
		mounted () {
		},
		updated () {
		},
		destroyed () {
		}
	};
</script>

<style scoped>

</style>
