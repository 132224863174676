import UserService from "src/services/UserService";
import ModuleCreator from "src/store/ModuleCreator";

export const types = {
  UPLOAD_IMAGE: "user/edit"
};
export const params = {
  name: "",
  last_name: "",
  email: "",
  email_verified_at: "",
  password: ""

};

/**
 @namespace
 @property  {object} module,
 @property  {object} module.state,
 @property  {object} modules.user,
 @property  {array}  modules.users
 **/

const userModule = {
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  types: types
};
export default ModuleCreator("user", UserService, userModule, types, params);
