<template>

	<page>
		<title-view>מסך פרוייקטים
			<v-btn slot="actions" btn @click="onEdit({})">יצירת פרוייקט</v-btn>
		</title-view>
		<row>
			<c-col>
				<projects-table @edit="onEdit"/>
			</c-col>
		</row>
		<popup-dialog v-model="openModal">
			<span slot="header">יצירת/עריכת פרוייקט</span>
			<project-form @save="openModal=false"/>
		</popup-dialog>

	</page>


</template>

<script>
	import ProjectForm from "src/view/projects/ProjectForm";
	import ProjectsTable from "src/view/projects/ProjectsTable";
	import * as ProjectModule from "../../store/modules/Project.module";

	export default {
		name: "projects-view",
		components: {ProjectsTable, ProjectForm},
		data() {
			return {
				openModal: false
			};
		},
		methods: {
			onEdit(project) {
				project.id ? this.$store.commit(ProjectModule.types.GET, project):
				this.$store.commit(ProjectModule.types.CREATE, project) ;
				this.openModal = true;
			}
		}

	};
</script>

<style scoped>

</style>
