<template>
	<v-row align="center" justify="center">
		<v-col>
			<slot></slot>
		</v-col>
	</v-row>
</template>
<script>

	export default {
		name: "page",
		props: [],
		components: {},
		data () {
			return {};
		},
		computed: {},
		methods: {},
		watch: {},
		created () {
		},
		mounted () {
		},
		updated () {
		},
		destroyed () {
		}
	};
</script>

<style scoped>

</style>
