<!--suppress UnnecessaryLabelJS -->
<template>
	<v-data-table
		:headers="headers"
		:items="projects"
		:loading="loading"
		class="elevation-1"
    :search="seek"
	>
    <template v-slot:top>
      <v-text-field
          v-model="seek"
          label="חיפוש חופשי"
          class="mx-4"
      ></v-text-field>
    </template>
		<v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
		<template #item.actions="{item}">
			<edit-btn @click="$emit('edit',item)"/>
		</template>
	</v-data-table>
</template>

<script>
	import ProjectRow from "src/view/projects/ProjectRow";
	import {types} from "src/store/modules/Project.module";
	import FilterAble from "src/mixins/FilterAble";

	export default {
		name: "projects-table",
		props: [],
		components: {
			ProjectRow
		},
		mixins: [new FilterAble(types)],
		data() {
			return {
        seek:"",
				loading: false,
				headers: [
					{text: "מזהה פרוייקט", value: "id"},
					{text: "שם פרוייקט", value: "name"},
					{text: "firebase prefix", value: "prefix"},
					{text: "domain", value: "domain"},
					{text: "actions", value: "actions"}

				],
				search: null,
				showDialog: false
			};
		},
		computed: {
			projects() {
				return this.$store.state.ProjectModule.projects;
			}
		},
		methods: {
			onEdit(Project) {
				this.$emit("edit", Project);
			}
		}
	};
</script>

<style>

</style>
