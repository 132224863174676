<script>
	export default {
		name: "cols-row",
		render(createElement) {
			let validSlots = this.$slots.default.filter(slot => slot.tag);
			let cCol = validSlots.map((slot) => {
				return createElement("c-col", {
					attrs: Object.assign({
						cols: 12,
						md: "auto"
					}, this.$attrs, slot.data.attrs)
				}, [slot]);
			});
			return createElement("v-row", {attrs: Object.assign({row: true}, this.row)}, [cCol]);
		},
		props: {
			row: {
				type: Object, default: () => {
				}
			}
		}
	};
</script>

<style scoped>

</style>
