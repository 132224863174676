export const helpers = {
  isCallback (foo) {
    return typeof foo === "function" && !this.isClass(foo);
  },

  isObject (obj) {
    return typeof obj === "object" && obj.constructor === Object;
  },

  isClass (fn) {
    return /^\s*class/.test(fn.toString());
  }

};

class Pipeline {
  constructor (pipes) {
    this._inject = [];
    this.pipes = pipes || [];
    this.breakIf = () => false;
  }

  inject (inject) {
    this._inject = Array.isArray(inject) ? inject : [inject];
  }

  pipe (stage) {
    this.pipes.push(stage);
    return this;
  };

  setBreak (foo) {
    this.breakIf = foo;
    return this;
  };

  async process (args) {
    return await process(this.pipes, args, this.breakIf, this._inject);
  };
}

let process = async function (pipes, stageOutput, breakIf, inject) {
  if (pipes.length === 0) {
    return stageOutput;
  }
  if (breakIf(stageOutput)) {
    return stageOutput;
  }
  let cloneStage = pipes.slice(0);
  let nextFoo = cloneStage.shift();
  let res = stageOutput;
  if (helpers.isObject(nextFoo)) {
    res = await nextFoo.handle.call(nextFoo, ...[stageOutput, ...inject]);
  } else if (helpers.isClass(nextFoo)) {
    let obj = new nextFoo();
    res = await obj.handle.call(obj, ...[stageOutput, ...inject]);
  } else if (helpers.isCallback(nextFoo)) {
    try {
      res = await nextFoo.call(null, ...[stageOutput, ...inject]);
    } catch (error) {
      let obj = new nextFoo();
      res = await obj.handle.call(obj, ...[stageOutput, ...inject]);
    }
  }

  return process(cloneStage, res, breakIf, inject);
};
export default Pipeline;
