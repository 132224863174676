/**
 * Created by elad on 20 נובמבר 2016.
 */

import config from "src/config/";
import store from "dev/store";

export function Config(key, value) {
    store.commit(types.SET, {key, value});
}

function createGetters() {
    let getters = {};
    Object.keys(config).forEach((key) => {
        getters[key + "Config"] = (state) => {
            return state[key];
        };
    });
    return getters;
}

export const types = {
    SET: "set/config"
};

export default {

    state: config,
    getters: createGetters(),
    mutations: {
        [types.SET](state, {key, value}) {
            let object = state;
            let stack = key.split(".");
            while (stack.length > 1) {
                object = object[stack.shift()];
            }
            object[stack.shift()] = value;
        }

    },
    actions: {},
    types: types
};
