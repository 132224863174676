import Vue from "vue";
import vuetify from "./plugins/vuetify";
import VeeValidate from "vee-validate";
import he from "vee-validate/dist/locale/he";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import Notification from "src/helpers/Notification";
import VueRouter from "vue-router";
import "./scaffoldings";
import "dev/helpers/RoleManager";
import {setBaseUrl} from "dev/packages/MyAxios";
import {types as AuthTypes} from "dev/store/modules/Auth.module";
import Connection from "dev/firebase/Connection";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
Connection.init();
Vue.use(VeeValidate, {
    locale: "he",
    dictionary: {
        he: {
            messages: he.messages
        }
    }
});


store.watch((state) => state.ConfigModule.request.baseUrl, (path) => {
    setBaseUrl(path);
});

Vue.use(VueRouter);
Vue.use(Notification);
Vue.config.productionTip = false;
store.dispatch(AuthTypes.GET).finally(() => {
    new Vue({
        vuetify,
        router,
        store,
        render: h => h(App)
    }).$mount("#app");
});
