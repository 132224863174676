<template>
	<v-card>
		<v-card-text>

			<smart-input
				v-for="input in schema"
				:type="getType(input)"
				:required="!!input.required"
				:label="input.label"
				:key="input.key"
				:name="input.key"
				:textarea="getType(input) === 'textarea'"
				v-model="model[input.key]"
			></smart-input>
		</v-card-text>
	</v-card>
</template>

<script>
	export default {
		name: "form-builder",
		props: {schema: Array, value: Object},
		components: {},
		data() {
			return {};
		},
		computed: {
			model: {
				get() {
					return this.value;
				}, set(val) {
					console.log(val);
				}
			}
		},
		methods: {
			getType(input) {
				return input.type || "text";
			}
		},

	};
</script>

<style scoped>

</style>
