<!--suppress UnnecessaryLabelJS -->
<template>
	<v-data-table
		:headers="headers"
		:items="organizations"
		:loading="loading"
    :search="seek"
		class="elevation-1"
	>
    <template v-slot:top>
      <v-text-field
          v-model="seek"
          label="חיפוש חופשי"
          class="mx-4"
      ></v-text-field>
    </template>
		<v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
		<template v-slot:item.insertAs="{ item }">
			<v-btn v-if="item.user" small color="primary" @click="loginAs(item.user)">כנס כמשתמש</v-btn>
		</template>
		<template v-slot:item.user.email="{item}">
			<a v-if="item.user" :href="`mailto:${item.user.email}`">{{ item.user.email }}</a>
		</template>
		<template v-slot:item.link_continue="{item}">
			<a :href="item.link_continue" target="_blank">קישור המשך</a>
		</template>
		<template v-slot:item.link_slide="{item}">
			<a :href="`https://yahad.clap.co.il/assets/2/atsmaut/v7/?theKey=${item.link_slide}`"
				target="_blank">קישור מצגת</a>
		</template>
		<template v-slot:item.actions="{ item }">
			<edit-btn @click="editItem(item)"></edit-btn>
		</template>
		<template v-slot:item.is_open="{ item }">
			<v-switch
				v-model="item.is_open"
				@change="onChange(item)"
				:label="item.is_open ? 'המשחק פתוח' : 'המשחק סגור'"
			></v-switch>
		</template>
		<template v-slot:item.actions="{ item }">
			<edit-btn @click="editItem(item)"></edit-btn>
      <v-btn :href="`/api/admin/organizations/json/${item.id}`" target="_blank">JSON
        <v-icon>
          download
        </v-icon>
      </v-btn>
      <v-btn small dark color="primary" fab :to="{name:'group-id-page',params:{org_id:item.id}}">
        <v-icon>fas fa-users</v-icon>
      </v-btn>
    </template>
	</v-data-table>
</template>

<script>
import {types} from "src/store/modules/Organization.module";
import FilterAble from "src/mixins/FilterAble";
import AuthService from "dev/packages/AuthService";

export default {
  name: "organizations-table",
  props: [],
  components: {},
  mixins: [new FilterAble(types)],
  data() {
    return {
      seek:"",
      loading: false,
      headers: [
        {text: "כנס כ", value: "insertAs", sortable: false},
        {text: "שם", value: "name"},
					{text: "code", value: "code"},
					{text: "סיסמה", value: "password"},
					// {text: "קישור הוראות", value: "link_rules"},
					// {text: "קישור המשך", value: "link_continue"},
					// {text: "קישור מצגת", value: "link_slide"},
					// {text: "סוג משחק", value: "game_type"},
					{text: "תאריך פתיחה", value: "will_open"},
					{text: "תאריך סגירה", value: "end_game"},
					{text: "המשחק פתוח?", value: "is_open"},
					{text: "שם רכז", value: "user.name"},
					{text: "דואר אלקטרוני", value: "user.email"},
					{text: "Actions", value: "actions", sortable: false},
				],
				search: null,
				showDialog: false
			};
		},
		computed: {
			organizations() {
				return this.$store.state.OrganizationModule.organizations;
			}
		},
		methods: {
			editItem(organization) {
				this.$emit("edit", organization);
			},
			async onChange(item) {
				this.loading = true;
				await this.$store.dispatch(types.OPEN_CLOSE, item);
				this.loading = false;
			},
			async loginAs(user) {
				if (!user) {
					alert("ללא רכז");
					return;
				}
				this.loading = true;
				await AuthService.loginAs(user.id);
				window.location.replace("/");
			},
			removeItem() {
				alert();
			}
		}
	};
</script>

<style>

</style>
