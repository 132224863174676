import GroupService from "src/services/GroupService";
import ModuleCreator from "src/store/ModuleCreator";

export const types = {};
export const params = {
	name:"",
	organization_id:"",

};

/**
@namespace
@property  {object} module,
@property  {object} module.state,
@property  {object} modules.group,
@property  {array}  modules.groups
**/

const module = {
	state: {},
	mutations: {},
	getters: {},
	actions: {},
	types: types
};
export default ModuleCreator("group", GroupService, module, types ,params);
