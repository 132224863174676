<template>
	<v-alert
		:value="true"
		:type="type"
	>
		{{message}}
	</v-alert>
</template>

<script>
	export default {
		name: "message-box",
		props: {
			// Specify a success, info, warning or error alert. Uses the contextual color and a pre-defined icon.
			type: {
				default: "error"
			},
			message: {
				require: true
			}
		},

		components: {},
		data () {
			return {};
		},
		computed: {

		},
		methods: {
			onCloseClick () {
				this.$emit("close");
			}
		},
		created () {
		},
		mounted () {
		},
		updated () {
		},
		destroyed () {
		}
	};
</script>

<style>

</style>
