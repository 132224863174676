import OrganizationService from "src/services/OrganizationService";
import ModuleCreator from "src/store/ModuleCreator";

export const types = {
	CLEAR_DATA: "organization/clear_data",
	OPEN_CLOSE: "organization/open_close",
};
export const params = {
	name: "",
	user_id: "",
	password: "",
	project: "",
	code: "",
	link_rules: "",
	link_continue: "",
	link_slide: "",
	end_game:""

};

/**
@namespace
@property  {object} module,
@property  {object} module.state,
@property  {object} modules.organization,
@property  {array}  modules.organizations
**/

const module = {
	state: {},
	mutations: {},
	getters: {},
	actions: {
		[types.CLEAR_DATA](context, org) {
			console.log(org);
			return OrganizationService.clearData(org.id);
		},
		async [types.OPEN_CLOSE](context, data) {
			let response = await OrganizationService.openClose(data.id,{is_open:data.is_open});
			context.commit(types.UPDATE,response);
			return response;
		},
	},
	types: types
};
export default ModuleCreator("organization", OrganizationService, module, types ,params);
