<template>
	<page>
		<navigation-drawer v-model="drawer" :routes="routes"></navigation-drawer>

		<v-app-bar
			app
			color="indigo"
			dark
		>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title>{{config.app.name}}</v-toolbar-title>
		</v-app-bar>

		<v-main style="padding:0;padding-top: 20px">
			<v-container
				class="fill-height"
				fluid
			>
				<v-layout justify-center align-start>
					<v-flex class="px-0">
						<router-view></router-view>
					</v-flex>
				</v-layout>
			</v-container>
		</v-main>
	</page>
</template>

<script>
	import NavigationDrawer from "src/scaffoldings/NavigationDrawer";

	export default {
		components: { NavigationDrawer },
		props: {
			source: String
		},
		data: () => ({
			drawer: true
		}),
		computed: {
			config () {
				return this.$store.state.ConfigModule;
			},
			defaultRouter () {
				return this.config.menu.default;
			},
			routes () {
				return this.config.menu.routes[this.defaultRouter].routes;
			}

		}
	};
</script>
