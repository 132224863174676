<!--suppress UnnecessaryLabelJS -->
<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:single-expand="singleExpand"
		:loading="loading"
		item-key="id"
		class="elevation-1"
		:expanded.sync="expanded"
		show-expand
	>
		<v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
		<template #item.complete="{item}">
			<v-simple-checkbox v-model="item.complete" disabled></v-simple-checkbox>
		</template>
		<template #item.url="{item}">
			<div>
				<a v-if="item.url" :href="imageTiny500(item.url)" target="_blank">
					<v-img v-if="isUriImage(item.url)" :src="imageTiny(item.url)"></v-img>
					<span v-else>לחץ לצפייה</span>
				</a>
				<span v-else>ללא תוצרים</span>
			</div>
		</template>
		<template v-slot:expanded-item="{ headers, item }">
			<td :colspan="headers.length">
				<pre style="direction: ltr">{{item}}</pre>
			</td>
		</template>

	</v-data-table>
</template>

<script>

	export default {
		name: "level-table",
		props: {groups: Object},
		components: {},
		data() {
			return {
				singleExpand: true,
				loading: false,
				expanded: [],
				headers: [
					{text: "קבוצה/שחקן", value: "name"},
					{text: "ניקוד", value: "score"},
					{text: "תוצר", value: "url"},
					{text: "סיים שלב", value: "complete"},
				],
				search: null,
			};
		},
		computed: {
			items() {
				return Object.keys(this.groups).map((groupId) => {
					return Object.assign({id: groupId}, this.groups[groupId]);
				});
			}
		},
		methods: {
			isUriImage(uri) {
				//make sure we remove any nasty GET params
				uri = uri.split("?")[0];
				//moving on, split the uri into parts that had dots before them
				var parts = uri.split(".");
				//get the last part ( should be the extension )
				var extension = parts[parts.length - 1];
				//define some image types to test against
				var imageTypes = ["jpg", "jpeg", "tiff", "png", "gif", "bmp"];
				//check if the extension matches anything in the list.
				if (imageTypes.indexOf(extension) !== -1) {
					return true;
				}
			},
			imageTiny500(src) {
				if (src) {
					let imageArr = src.split("upload");
					return imageArr[0] + "upload/w_500,c_limit,q_90" + imageArr[1];
				}
				return "";
			},
			imageTiny(src) {
				if (src) {
					let imageArr = src.split("upload");
					return imageArr[0] + "upload/w_60,q_80" + imageArr[1];
				}
				return "";
			}
		}
	};
</script>
