<template>
	<v-layout align-center justify-center>
		<v-flex xs12 sm8 md4>
			<v-layout>
				<v-flex>
					<message-box
						v-if="error"
						:message="error"
					>
					</message-box>
				</v-flex>
			</v-layout>
			<v-layout>
				<v-flex>
					<v-card class="elevation-12">
						<v-toolbar dark color="primary">
							<v-toolbar-title>התחברות</v-toolbar-title>
							<v-spacer></v-spacer>

						</v-toolbar>
						<v-form ref="form" @submit.prevent="onSubmit">
							<v-card-text>
								<smart-input :required="true" v-model="form.email" prepend-icon="person" name="login" label="דואר אלקטרוני" type="email"/>
								<smart-input required v-model="form.password" id="password" prepend-icon="lock" name="password" label="סיסמא" type="password"/>

							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn type="submit" color="primary">התחברות</v-btn>
							</v-card-actions>
						</v-form>
					</v-card>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
	import { types } from "src/store/modules/Auth.module";

	export default {
		data: () => ({
			disabled: false,
			form: {
				email: null,
				password: null
			},
			error: null
		}),
		props: {
			source: String
		},
		methods: {
			required (v) {
				return [!!v || "שדה חובה"];
			},
			onSubmit () {
				this.disabled = true;
				if (this.$refs.form.validate()) {
					this.$store.dispatch(types.LOGIN, this.form).then(
						() => {
							this.disabled = false;
							this.$router.push({ name: "home-page" });
						}, (error) => {
							this.disabled = false;
							this.error = error.response.data.errors.email[0];
						}
					);
				}
			}
		}
	};
</script>

<style scoped>

</style>
