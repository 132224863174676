import LaravelErrors from "src/helpers/LaravelErrors";

class FormView {
  constructor (module, modelName, withFiles = false) {
    let types = module.types || module;
    let oldForm = {};
    let originalForm = {};
    const mixin = {
      data: () => {
        return {
          withFiles: false,
          inProgress: false,
          inputErrors: {},
          form: {}
        };
      },
      watch: {
        form: {
          deep: true,
          handler (newForm) {
            for (let prop in newForm) {
              if (newForm.hasOwnProperty(prop)) {
                if (newForm[prop] !== oldForm[prop]) {
                  this.inputErrors[prop] = null;
                }
              }
            }
            oldForm = Object.assign({}, newForm);
          }
        }
      },
      methods: {
        onReset () {
          this.$store.commit(types.CREATE, {});
          this.$emit("clear-errors");
        },
        beforeSubmit () {
          return true;
        },
        getDispatchType() {
          return types.SAVE;
        },
        getData(e) {
          let data = this.form;
          if (withFiles) {
            data = new FormData(e.target);
            if (this.form.id) {
              data.append("id", this.form.id);
              data.id = this.form.id;
            }
          }
          return data;
        },
        onSubmit: function (e) {
          this.$notfication.clean();
          let data = this.getData(e);
          let beforeSubmit = this.beforeSubmit(data);
          if (beforeSubmit === false) {
            return;
          }

          let submitButton = e.target.querySelector("button[type=submit]");
          if (submitButton) submitButton.disabled = true;
          // noinspection JSUnusedGlobalSymbols
          this.inProgress = true;
          this.inputErrors = {};
          let isNew = !this.form.id;
          this.sendForm(data).then(
            (response) => {
              // noinspection JSUnusedGlobalSymbols
              this.inProgress = false;
              this.$emit("save");
              if (isNew) {
                this.$emit("create");
              } else {
                this.$emit("update");
              }
              if (submitButton) submitButton.disabled = false;
              this.onSuccess(response);
            },
            (error) => {
              // noinspection JSUnusedGlobalSymbols
              this.inProgress = false;
              if (error.response && error.response.status === 422 && error.response.data && error.response.data.errors) {
                this.inputErrors = LaravelErrors.getFirstErrorOnly(error.response.data.errors);
              }
              this.onError();
              if (submitButton) submitButton.disabled = false;
            }
          );
        },
        sendForm(data) {
          return this.$store.dispatch(this.getDispatchType(), data);
        },
        onSuccess () {
          this.$notfication.success(this.getSuccessMessage());
          this.onReset();
        },
        onError () {
          this.$notfication.error();
        },
        getSuccessMessage () {
        }
      },
      mounted: function () {
        originalForm = Object.assign({}, this.form);
      },
      computed: {}
    };

    if (modelName && module.state && module.state[modelName]) {
      mixin.computed[modelName] = function () {
        return module.state[modelName];
      };
      mixin.watch[modelName] = {
        handler: function () {
          this.form = Object.assign({}, this[modelName]);
        },
        immediate: true

      };
    }
    return mixin;
  }
}

export default FormView;
