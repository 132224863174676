<template>

	<page>
		<title-view>תצוגת שחקנים/קבוצות
		</title-view>
		<!--		<row>-->
		<!--			<c-col>-->
		<!--				<a :href="songLink" target="_blank">קישור לשירון הקהילתי</a>-->
		<!--			</c-col>-->
		<!--		</row>-->
		<row>
			<c-col>
				<groups-table v-if="showGroups" :params="filters"/>
			</c-col>
			<!--			<c-col>-->
			<!--				<levels-table/>-->
			<!--			</c-col>-->
		</row>

	</page>


</template>

<script>
	import GroupsTable from "src/view/groups/GroupsTable";
	import LevelsTable from "src/view/levels/LevelsTable";
	import OrganizationModule from "src/store/modules/Organization.module";

	export default {
		name: "groups-view",
		components: {LevelsTable, GroupsTable},
		data() {
			return {
				openModal: false,
				showGroups: false,
				filters: {},
			};
		},
		computed: {
			user() {
				return this.$store.state.AuthModule.user;
			},
			organization() {
				return this.$store.state.OrganizationModule.organization || this.user.organization;
			},
			songLink() {
				return `https://yahad.clap.co.il/assets/2/atsmaut/v7/?theKey=${this.user.organization.link_slide}`;
			}
		},
		methods: {},
		async created() {
			if (this.$route.params.org_id) {
				await this.$store.dispatch(OrganizationModule.types.GET, this.$route.params.org_id);
				this.filters = {code: this.organization.code};
				this.showGroups = true;
			} else {
				this.showGroups = true;
			}
		}

	};
</script>

<style scoped>

</style>
