import ProjectService from "src/services/ProjectService";
import ModuleCreator from "src/store/ModuleCreator";

export const types = {};
export const params = {
	name:"",
	prefix:"",
	domain:"",
	schema:"",
	cloudinary_secret:"",
	cloudinary_key:"",

};

/**
@namespace
@property  {object} module,
@property  {object} module.state,
@property  {object} modules.project,
@property  {array}  modules.projects
**/

const module = {
	state: {},
	mutations: {},
	getters: {},
	actions: {},
	types: types
};
export default ModuleCreator("project", ProjectService, module, types ,params);
