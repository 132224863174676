<template>
	<v-navigation-drawer
		v-model="open"
		clipped
		fixed
		app
		right
	>
		<v-list dense>
			<v-list-item v-for="route in routes" :key="route.name" :to="route">
				<v-list-item-action v-if="route.icon">
					<v-icon>{{route.icon}}</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>{{route.title}}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item @click="doLogout">
				<v-list-item-action>
					<v-icon>logout</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>התנתקות</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	import { types as AuthTypes } from "src/store/modules/Auth.module";

	export default {
		name: "navigation-drawer",
		props: {
			value: {},
			routes: {
				type: Array,
				default: () => []
			}
		},
		components: {},
		data () {
			return {
				open: true
			};
		},
		computed: {},
		created () {
		},
		methods: {
			doLogout () {
				this.$store.dispatch(AuthTypes.LOGOUT).then(() => {
					location.reload();
				});
			}
		},
		watch: {
			value: {
				immediate: true,
				handler () {
					this.open = this.value;
				}
			}

		}

	};
</script>

<style scoped>

</style>
