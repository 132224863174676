export default {
	default: "admin",

	routes: {
		admin: {
			routes: [
				{title: "משתמשים", name: "users-page", icon: "fas fa-users"}
			]
		}
	}
};
