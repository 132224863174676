<!--suppress ALL -->
<!--suppress UnreachableCodeJS -->
<template>

	<form @submit.prevent="onSubmit">
		<v-card>
			<v-card-text>
				<smart-input :error="inputErrors.name" required label='שם קבוצה' v-model="form.name"></smart-input>
				<smart-input v-if="!isNew" :readonly="!isNew" :disabled="!isNew"
					label='קוד'
					v-model="form.code"/>
				<smart-input label="סיסמת קבוצה" v-model="form.password" minlength="4"
					:error="inputErrors.password"
				></smart-input>
				<smart-input type="datetime-local"
					:error="inputErrors.will_open"
					label='תאריך פתיחה'
					v-model="form.will_open"
					hint="המשחק לא יפתח אוטומטית אלא יש לפתוח את הקישור"
				/>
				<smart-input type="datetime-local"
					:error="inputErrors.end_game"
					label='תאריך סיום'
					v-model="form.end_game"
				/><smart-input
          type="number"
					:error="inputErrors.users_limit"
					label='הגבלת משתמשים'
					v-model="form.users_limit"
				/><smart-input
          type="number"
					:error="inputErrors.games_limit"
					label='הגבלת משחקים'
					v-model="form.games_limit"
				/>
        <smart-input
            textarea
            style="direction: ltr !important;"
					:error="inputErrors.fields"
					label='שדות(יש לבקש מהחברה)'
					v-model="form.fields"
				/>
				<v-switch
					v-model="form.is_open"
					:label="form.is_open ? 'המשחק פתוח' : 'המשחק סגור'"
				></v-switch>
			</v-card-text>
		</v-card>
		<form-builder v-model="form" :schema="schema"></form-builder>
		<v-spacer/>
		<v-card class="my-2" v-if="isNew">
			<v-card-title>פרטי רכז</v-card-title>
			<v-card-text>
				<smart-input :error="inputErrors.email" label='דואר אלקטרוני רכז'
					v-model="form.email"></smart-input>
				<smart-input :error="inputErrors.name" label='שם רכז' v-model="form.manager_name"></smart-input>
				<smart-input :error="inputErrors.user_password" label='סיסמא למערכת'
					v-model="form.user_password"></smart-input>
			</v-card-text>
		</v-card>
		<div class="my-5 text-center">
			<v-btn type="submit" width="200" :loading="inProgress" color="success">שמירה</v-btn>
		</div>
		<row>
			<c-col>
				<div class="text-left" v-if="!isNew">
					<v-btn color="denger" small type="button" @click="restartData">איפוס מסד נתונים</v-btn>
				</div>
				<div class="text-right" v-if="!isNew">
					<remove-btn @remove="removeOrganization" :options="removeOptions">מחיקת קהילה</remove-btn>
				</div>
			</c-col>
		</row>
	</form>
</template>


<script>
	import OrganizationModule from "src/store/modules/Organization.module";
	import FormView from "src/mixins/FormView";
	import FormBuilder from "dev/components/FormBuilder/index";

	export default {
		props: [],
		name: "organization-form",
		components: {FormBuilder},
		data() {
			return {
				form: {
					name: null,
				},
				removeOptions: {
					title: "מחיקת ארגון -  האם אתה בטוח??",
					text: "זוהי פעולה בלתי הפיכה!!"
				},
			};
		},
		mixins: [new FormView(OrganizationModule, "organization")],
		computed: {
			isNew() {
				return !this.organization.id;
			},
			schema() {
				let schema = this.$store.getters.project.schema || [];
				if (typeof schema === "string") {
					return JSON.parse(schema);
				}
				return schema;
			}

		},
		created() {
			let externalData = this.organization.external_data || [];
			let values = {};
			externalData.forEach((obj) => {
				values[obj.key] = obj.value;
			});
			this.schema.forEach((input) => {
				if (!this.form[input.key]) {
					this.$set(this.form, input.key, values[input.key] || null);
				}
			});

			if (this.organization.end_game){
				this.form.end_game = moment(this.organization.end_game).format("Y-MM-DDTHH:mm");
			}
			if (this.organization.will_open){
				this.form.will_open = moment(this.organization.will_open).format("Y-MM-DDTHH:mm");
			}if (this.organization.fields){
				this.form.fields = JSON.stringify(this.organization.fields);
			}
			if (this.isNew) {
				this.form.password = Math.ceil(Math.random() * 10000);
        this.form.fields = "{}";
        this.form.users_limit=30;
        this.form.games_limit=1;

			}
		},
		methods: {
			onSuccess(response) {
				let code = response.code;
				swal.fire("הקבוצה נוצרה בהצלחה", `קוד הקבוצה הוא ${code}`, "success");
				this.onReset();
			},
			async removeOrganization() {
				this.$store.dispatch(OrganizationModule.types.REMOVE, this.organization);
				this.$emit("close");
			},
			async restartData() {
				let response = await this.$notfication.warning("מחיקת נתונים - האם אתה בטוח?", "פעולה זו בלתי הפיכה!",
					{
						showLoaderOnConfirm: true,
						allowOutsideClick: false,
						preConfirm: async (response) => {
							let res = await this.$store.dispatch(OrganizationModule.types.CLEAR_DATA, this.form);
							this.$emit("close");
							return res;
						},
					});
				if (response.value) {
					this.$store.dispatch(OrganizationModule.types.CLEAR_DATA, this.form);
					this.$emit("close");
				}
			}
		}
	};
</script>
